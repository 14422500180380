#footer {
  background-color: #67A8D6;
  border: unset;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  text-align: center;
  height: auto;
}
#footer hr {
  width: 70%;
  border-color: #000;
  color: #000;
  border-style: solid;
}
.footerTop {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 25em;
  width: 70%;
}
.footerTop .footerList {
  text-align: center;
}
.footerTop .item {
  margin: 0.2em;
}
.footerTop .item.icon {
  display: none;
}
.footerTop a {
  color: #FFFFFF;
}
.footerTop .footerList > div {
  margin: 0.5em;
}
.logoWhiteDiv {
  display: flex;
  justify-content: center;
}
.logoWhite {
  width: 25em;
  height: -moz-fit-content;
  height: fit-content;
}
.footerBottom {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerBottom a.item {
  text-decoration: underline;
  -webkit-text-decoration-color: #FFFFFF;
          text-decoration-color: #FFFFFF;
  font-size: 15px;
}
.footerBottom .footerList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerTop .footerList {
  display: flex;
  flex-direction: column;
  padding: 2em;
}
.footerBottom .footerList .item {
  padding: unset;
  margin: 0.4em 0;
}
.footerTop .footerList .item {
  padding: unset;
}
.footerList .item {
  color: #fff;
  line-height: 18px;
  font-size: 13pt;
}
.footerList .item i {
  margin: 0 0.3em 0 0;
  font-size: 13pt;
}
.footerList .item i.ri-copyright-line {
  margin-right: 0.2em;
}
.footerList .item i.ri-circle-fill {
  align-self: center;
  font-size: 10pt;
}
.bannerLeftDiv {
  order: 2;
}
.bannerRightDiv {
  order: 2;
}
.footerListWrapper {
  order: 1;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .footerTop {
    width: 70%;
  }
  .footerList {
    margin: unset;
  }
  .footerBottom .footerList {
    flex-direction: row;
  }
  .footerBottom .footerList .item {
    margin: 0 0.5em;
  }
}
@media only screen and (min-width: 830px) {
  .footerTop {
    min-height: auto;
    width: auto;
  }
  .bannerLeftDiv {
    order: 0;
  }
  .bannerRightDiv {
    order: 2;
  }
  .footerListWrapper {
    width: auto;
  }
}
@media only screen and (min-width: 1750px) {
  .footerTop .footerList {
    flex-direction: row;
  }
  .footerTop .footerList .item {
    margin: 0 0.5em;
  }
  .footerTop .footerList .item.icon {
    display: grid;
    justify-content: center;
  }
  .footerTop {
    flex-direction: row;
    flex-wrap: unset;
    width: 60%;
    align-items: center;
  }
}
/* .footerName {
    font-weight: bold;
} */
